<template>
  <div>
    <main class="main">
      <div class="col_main">
        <h2>
          <div class="title">{{ title }}</div>
          <a class="help_link" target="_blank" :href="'https://dataanalysis.zendesk.com/hc/ja/sections' + helpLink">i</a>
          <p class="button" @click="backFromImage()" v-if="selectedImage">戻る</p>
          <p class="button" @click="downloadImage()" v-if="!tableauInfo.images && !noImage">画像ダウンロード</p>
          <vue-select
            placeholder="画像 ダウンロード"
            :clearable="false"
            :searchable="false"
            label="title"
            :options="tableauInfo.images"
            v-model="selectedImage"
            v-if="tableauInfo.images"
          ></vue-select>
          <vue-select
            placeholder="CSV ダウンロード"
            :clearable="false"
            :searchable="false"
            label="title"
            :options="tableauInfo.csv"
            v-model="selectedDownload"
            v-if="tableauInfo.csv"
          ></vue-select>
          <p class="button" @click="reload()">抽出条件をクリア</p>
        </h2>
        <div class="vizContainer_wrapper">
          <div class="col_main__block" id="vizContainer"></div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import _MENU_JSON from '../assets/data/menu.json';
import {TableauEventType, TableauViz, TableauDialogType, CrosstabFileFormat} from '../assets/js/tableau.embedding.3.latest.min.js';

export default {
  name: 'analysis',
  components: { 'vue-select': vSelect },
  data() {
    return {
      name: this.$route.query.name,
      title: '',
      helpLink: '',
      tableauInfo: {},
      viz: {},
      selectedDownload: '',
      selectedImage: '',

      noImage: false,
      currentSheet: null,
    };
  },
  created() {
    const item = _MENU_JSON.find((el) => el.title == this.name);
    console.log('ITEM', item)
    const allow_dashboard = this.$auth.user['https://medicalbi.jp/allow_dashboard'];
    if (!allow_dashboard.includes(item.key)) {
      window.location.href = '/';
      return;
    }
    this.$loadScript('https://online.tableau.com/javascripts/api/tableau-2.9.1.min.js').then(() => {
      this.initViz();
    });
    this.tableauInfo = _MENU_JSON.find((el) => el.title == this.name)['tableau'];
    this.title = item['title'];
    this.helpLink = item['help_page'];
    this.noImage = false;
    this.currentSheet = null;
  },
  methods: {
    initViz: function() {

      const viz = new TableauViz();   // create the authoring viz object
      this.viz = viz;

      let width = this.tableauInfo.width;
      if (process.env.NODE_ENV == 'development' && this.tableauInfo.width_dev) {
        width = this.tableauInfo.width_dev;
      } else if (process.env.NODE_ENV == 'staging' && this.tableauInfo.width_stg) {
        width = this.tableauInfo.width_stg;
      }
      let height = this.tableauInfo.height;
      if (process.env.NODE_ENV == 'development' && this.tableauInfo.height_dev) {
        height = this.tableauInfo.height_dev;
      } else if (process.env.NODE_ENV == 'staging' && this.tableauInfo.height_stg) {
        height = this.tableauInfo.height_stg;
      }

      if (this.title === '抗菌薬処方患者数分析') {
        width = '1480px';
      }

      const options = {
        hideTabs: true,
        hideToolbar: true,
        device: 'desktop',
        width: width,
        height: height,
        onFirstInteractive: () => this.listenToMarksSelection()
      };
      if (this.title === '後発医薬品数量割合' || this.title === '後発医薬品数量分析' ||
          this.title === 'フォーミュラリー 経済シミュレーション' || this.title === '経済シミュレーション') {
          options.onFirstInteractive = () => this.listenToMarksSelection()
      }
      let vizurl = this.tableauInfo.vizurl;
      if (process.env.NODE_ENV == 'development') {
        vizurl = this.tableauInfo.vizurl_dev;
      } else if(process.env.NODE_ENV == 'staging') {
        vizurl = this.tableauInfo.vizurl_stg;
      }

      var token = this.$auth.user['https://medicalbi.jp/tableau_token'];
      console.log('TOKEN', token)

      viz.src = vizurl;
      viz.height = this.tableauInfo.height;
      viz.width = this.tableauInfo.width;
      viz.toolbar = 'hidden';
      viz.hideTabs = 'true';
      viz.token = token;
      viz.addEventListener(TableauEventType.FirstInteractive, () => this.listenToMarksSelection())
      viz.addEventListener(TableauEventType.TabSwitched, this.onMarksSelection);
      // add viz to HTML code
      document.getElementById('vizContainer').appendChild(viz);
    },

    listenToMarksSelection: function() {
      this.onMarksSelection();
    },
    onMarksSelection: function() {
      const sheetName = this.viz.workbook.activeSheet?.name;
      if (!this.selectedImage) {
        this.currentSheet = sheetName;
      }

      this.tableauInfo.csv = this.tableauInfo[sheetName];
      this.tableauInfo.images = this.tableauInfo[sheetName + '_images'];

      console.log(sheetName)
      if (sheetName == '医薬品の内訳' || sheetName == '医薬品内訳' || sheetName == '成分内訳') {
        this.noImage = true;
      } else {
        this.noImage = false;
      }


      let height = this.tableauInfo.height;
      if (process.env.NODE_ENV == 'development' && this.tableauInfo.height_dev) {
        height = this.tableauInfo.height_dev;
      } else if(process.env.NODE_ENV == 'staging' && this.tableauInfo.height_stg) {
        height = this.tableauInfo.height_stg;
      }
      if (sheetName === '経済性シミュレーション_詳細' && this.tableauInfo.height_detail) {
        height = this.tableauInfo.height_detail;
      }
      this.viz.setFrameSize(parseInt(this.tableauInfo.width, 10), parseInt(height, 10));
    },

    downloadImage: function() {
      // this.viz.showExportImageDialog();
      // this.viz.displayDialogAsync(TableauDialogType.ExportPDF);
      this.viz.exportImageAsync();
    },
    backFromImage: function() {
      this.selectedImage = null;
      
      if (this.currentSheet != null) {
        console.log('BackTo', this.currentSheet)
        this.viz.workbook.activateSheetAsync(this.currentSheet);  
      } else {
        console.log('BackTo', this.tableauInfo.home_sheet)
        this.viz.workbook.activateSheetAsync(this.tableauInfo.home_sheet);
      }
    },
    downloadImageWithName: function(worksheet) {
      console.log(worksheet)

      this.viz.workbook.activateSheetAsync(worksheet).then(() => {
        // this.viz.showExportImageDialog();
        // this.viz.displayDialogAsync(TableauDialogType.ExportPDF);
        this.viz.exportImageAsync();
      });
    },
    downloadCsv: function(worksheet) {
      // this.viz.showExportCrossTabDialog(worksheet);

      const crosstabFileFormat = CrosstabFileFormat.CSV;
      this.viz.exportCrosstabAsync(worksheet, crosstabFileFormat);
    },
    reload: function() {
      this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },
  },
  beforeDestroy() {
    this.viz.dispose();
  },
  watch: {
    selectedDownload(value) {
      if (value.worksheet) {
        console.log('Download:', value.worksheet);
        console.log('Sheets:', this.viz.workbook)
        this.downloadCsv(value.worksheet);
        this.selectedDownload = '';
      }
    },
    selectedImage(value) {
      if (value && value.worksheet) {
        console.log('Image', value);
        this.downloadImageWithName(value.worksheet);
      }
    }
  },
};
</script>

<style scoped lang="scss">
.col_main {
  width: 100%;
  h2 {
    background: #f0f0f0;
    box-shadow: 5px 5px 5px rgba(23, 23, 23, 0.05) inset, -5px -5px 5px rgba(23, 23, 23, 0.05) inset;
    font-size: 2.6rem;
    font-weight: bold;
    align-items: center;
    margin: 10px auto 24px;
    padding: 2px 10px;

    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: flex-end;
  }
  .help_link {
    width: 26px;
    font-family: 'Times New Roman';
    font-size: 18px;
    text-align: center;
    margin: 0;
    margin-left: 5px;
    margin-right: auto;
    border: 1px solid #757575;
    border-radius: 50%;
    background-color: white;
    cursor: pointer;
    &:hover {
      background-color: #e8ffe2;
    }
  }
}
.button {
  cursor: pointer;
  height: 3.4rem;
  margin: 1px 10px 0 0;
  border: 1px solid #9c9c9c;
  border-radius: 4px;
  padding: 5px 16px;
  color: var(--text-color);
  background-color: #fbfbfb;
  text-align: center;
  font-size: 1.6rem;
  &:hover {
    background-color: #e8ffe2;
  }
}
.vizContainer_wrapper {
  text-align: center;
  #vizContainer {
    display: inline-block;
    margin-left: -8px;
  }
}
.v-select {
  width: 380px;
  font-size: 1.6rem;
  margin: 1px 10px 0 0;
  color: var(--text-color);
  background-color: #fbfbfb;
  ::v-deep .vs__dropdown-toggle {
    border: 1px solid #9c9c9c;
  }
  ::v-deep .vs__search,
  ::v-deep .vs__search:focus {
    margin: 5px 0 -2px;
    color: var(--text-color);
    font-weight: 700;
    font-size: 1.6rem;
    cursor: pointer;
  }
  ::v-deep .vs__dropdown-option--highlight {
    background: var(--assort-color) !important;
  }
}
::v-deep .vs--searchable .vs__dropdown-toggle {
  cursor: pointer;
}

.select {
  font-size: 1.6rem;
  height: 3.4rem;
  padding: 5px 6px;
  margin-right: 14px;
  color: var(--text-color);
  background-color: #fbfbfb;
  border: 1px solid #9c9c9c;
  border-radius: 4px;
  &:hover {
    background-color: #e8ffe2;
  }
}
</style>
